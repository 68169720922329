import React from 'react'
import styled from 'styled-components'

import { smartypants } from '../../utils/smartypants'
import { postExcerpt } from '../../utils/postExcerpt'
import { PortableText } from '../content/PortableText'
import { H1 } from '../styled/H1'
import { H3 } from '../styled/H3'
import { PageLink, PageLinkShadowless } from '../styled/PageLink'

const Byline = styled.p`
  display: block;
  margin-bottom: ${({ theme }) => theme.rhythm(1)};
  margin-top: ${({ theme }) => theme.rhythm(-1)};
  ${({ theme }) => theme.scale(-1 / 5)}

  & > * {
    margin-inline-start: ${({ theme }) => theme.rhythm(1 / 4)};
  }
  & > *:not([hidden]):first-child {
    margin-left: 0;
  }
`

const BylineInList = styled(Byline)`
  margin: 0;
`

export const Article = styled.article`
  margin: ${({ theme }) => theme.rhythm(1)} 0;
`

export const LinkContainer = styled.div`
  & a {
    margin-inline-start: ${({ theme }) => theme.rhythm(1 / 2)};
  }
  & a:first-child {
    margin-left: 0;
  }
`

export function ItemEntry({
  isSingle,
  title,
  slug,
  byline,
  excerpt,
  beforeContent,
  content,
  afterContent,
  extraLink,
}) {
  return (
    <Article className="h-entry">
      {isSingle ? (
        <H1 className="p-name">{smartypants(title)}</H1>
      ) : (
        <H3 className="p-name">
          <PageLinkShadowless to={slug}>
            {smartypants(title)}
          </PageLinkShadowless>
        </H3>
      )}
      {isSingle ? (
        <Byline>{byline}</Byline>
      ) : (
        <BylineInList>{byline}</BylineInList>
      )}
      <p className="p-summary" hidden={isSingle}>
        {postExcerpt(excerpt, content)}
      </p>
      {beforeContent}
      {isSingle && <PortableText text={content} className="e-content" />}
      {afterContent}
      <LinkContainer>
        <PageLink className="u-url" to={slug} hidden={isSingle}>
          Read more
        </PageLink>
        {extraLink}
      </LinkContainer>
    </Article>
  )
}
