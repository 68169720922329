import React from 'react'
import { PageLink, PageLinkExternal } from '../styled/PageLink'

export function BylineAuthor({
  author,
  hidden,
  nameHidden,
  nameLink = true,
  website,
  websiteText = '',
}) {
  const authorWebsite = website ?? author.website

  return (
    <span className="p-author h-card" rel="author" hidden={hidden}>
      {nameLink ? (
        <PageLink to="/about" hidden={nameHidden}>
          {author.name}
        </PageLink>
      ) : (
        <span className="p-name" hidden={nameHidden}>
          {author.name}
        </span>
      )}
      {authorWebsite && (
        <PageLinkExternal
          href={authorWebsite}
          className="u-url"
          hidden={!nameHidden}
        >
          {websiteText}
        </PageLinkExternal>
      )}
    </span>
  )
}
