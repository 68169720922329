import { excerpt } from '../utils/excerpt'
import { smartypants } from '../utils/smartypants'
import { portableToPlain } from '../utils/portableToPlain'

export function postExcerpt(text, fallback, length = 140) {
  if (Array.isArray(text)) {
    text = portableToPlain(text)
  }
  if (Array.isArray(fallback)) {
    fallback = portableToPlain(fallback)
  }
  return smartypants(text) || excerpt(smartypants(fallback), length)
}
