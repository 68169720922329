import React from 'react'
import { formatDate } from '../../utils/formatDate'

export function BylineDate({ date, customFormat, className }) {
  return (
    <time className={`dt-published ${className}`} dateTime={date}>
      {formatDate(date, customFormat)}
    </time>
  )
}
