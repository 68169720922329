export function excerpt(text, length = 140) {
  let excerpt = text
    .split('\n')
    .filter(line => line !== '')
    .join('\n')
    .substr(0, 140)
  if (text.length > length) {
    excerpt = excerpt.substr(0, length - 1).trim() + '…'
  }
  return excerpt
}
